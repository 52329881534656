<template>
  <div class="coupon">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$fanyi('我的券包')" name="我的卷包">
        <div class="main" v-loading="loading">
          <!-- 优惠券列表 -->
          <div class="couponList">


            <div class="item" v-show="item.status !== 20" v-for="(item, index) in couponList" :key="index" :class="{
              item2: item.type == 30 && item.status == 10,
            }">
              <div class="left">
                <span v-if="item.type == 30">{{ $fanyi('代金券') }}</span>
                <span v-if="item.type == 10">{{ $fanyi('优惠券') }}</span>
              </div>
              <div class="right">
                <p>NO.{{ item.number }}</p>
                <p class="title">{{ item.title }}</p>
                <p v-if="item.type == 30 && item.status == 10" class="Orderamount">
                  {{ $fanyi('订单金额') }} > ${{ item.restrictions }}
                </p>
                <p>
                  {{ $fanyi('有效期') }}:<br />{{ item.free_datetime_start }}--
                  {{ item.free_datetime_end }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane :label="$fanyi('使用记录')" name="使用记录">
        <div class="main">
          <!-- 使用列表 -->
          <div class="couponRecord">
            <div class="search">
              <el-select v-model="datas.type" :placeholder="$fanyi('请选择')">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <div class="input">
                <el-input v-model="datas.keywords" :placeholder="$fanyi('请输入内容')"></el-input>
              </div>
              <el-button type="primary" @click="couponRecord">{{
                $fanyi('搜索')
              }}</el-button>
            </div>
            <div class="table">
              <el-table :data="tableData" style="width: 100%" border :header-cell-style="{ background: '#F0F0F0' }">
                <el-table-column prop="rowNumber" label="NO" align="center" width="50">
                </el-table-column>
                <el-table-column prop="number" :label="$fanyi('优惠券/代金券编码')" align="center" width="175">
                </el-table-column>
                <el-table-column prop="title" :label="$fanyi('类别')" align="center">
                  <template slot-scope="scope">
                    <div>
                      {{ $fanyi(scope.row.title) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="free_kin" :label="$fanyi('额度/可使用次数')" align="center" width="130px">
                </el-table-column>
                <el-table-column prop="address" :label="$fanyi('生效时间段')" align="center" width="200px">
                  <template slot-scope="scope">
                    <p>{{ scope.row.free_datetime_start }}</p>

                    <p>{{ scope.row.free_datetime_end }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="updatetime" :label="$fanyi('使用日期')" align="center" width="100px">
                </el-table-column>
                <el-table-column prop="address" :label="$fanyi('使用状态')" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status == 10">
                      {{ $fanyi('待使用') }}
                    </span>
                    <span v-else-if="scope.row.status == 20">
                      {{ $fanyi('已使用') }}
                    </span>
                    <span v-else-if="scope.row.status == 30">
                      {{ $fanyi('已过期') }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="order_sn" :label="$fanyi('订单号')" align="center" width="200">
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="allpag">
              <el-pagination @current-change="handleCurrentChange" :current-page="datas.page" :page-size="10"
                :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next, jumper" :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

export default {
  data() {
    return {
      activeName: '我的卷包',
      loading: false,
      type: 1,
      couponList: [],
      datas: {
        page: 1,
        pageSize: 10,
        keywords: '',
        type: '',
      },
      options: [
        {
          label: this.$fanyi('已使用'),
          value: '已使用',
        },
        {
          label: this.$fanyi('已过期'),
          value: '已过期',
        },
      ],
      value: '',
      tableData: [],
      total: 0,
    }
  },
  created() {
    this.couponRecord()
    this.couponGet()
  },
  methods: {
    couponGet() {
      this.loading = true
      this.$api.couponGet({ page: 1, pageSize: 999 }).then((res) => {
        this.loading = false
        this.couponList = res.data.data

      })
    },
    couponRecord() {
      this.$api.couponRecord(this.datas).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    handleCurrentChange(val) {
      this.datas.page = val
      this.couponRecord()
    },

    handleClick() { },
  },
}
</script>
<style lang="scss" scoped>
.coupon {
  background-color: #f8f7f7;
  min-height: 480px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;

  /deep/ .el-tabs__nav-scroll {
    padding-left: 30px;
    font-size: 16px;
    padding-top: 10px;
    height: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  /deep/ .el-table .cell {
    line-height: 14px;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
  }

  .main {
    background-color: #fff;
    min-height: 400px;

    margin: 0 13px 0 13px;
    width: 1178px;

    // 优惠卷列表
    .couponList {
      padding-left: 20px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 420px;
        height: 180px;
        background: url('../../../../assets/youhuijuan/white.svg');
        padding: 20px 0;
        margin-right: 30px;
        margin-bottom: 20px;
        display: flex;

        .left {
          width: 136px;
          height: 64px;
          background: #ffffff;
          transform-origin: left top;
          transform: rotate(-90deg);
          margin-top: 138px;
          margin-right: -70px;
          margin-left: 20px;
          border: 1px solid transparent;
          background: #ffffff;
          border-radius: 4px;
          border: 2px solid #608bff;
          font-size: 24px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #608bff;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            word-spacing: 15px;
          }

          // padding: 20px 10px 20px 20px;
        }

        .right {
          margin-left: 20px;

          // padding: 10px 0;
          .title {
            font-size: 36px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            margin: 15px 0px;

            width: 260px;
            height: 60px;
            display: flex;
            line-height: 30px;

            align-items: center;
            font-size: 30px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #608bff;
          }

          p {
            margin-top: 5px;
            font-size: 12px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
          }
        }
      }

      .item2 {
        background: url('../../../../assets/youhuijuan/white.svg');

        .left {
          width: 136px;
          height: 64px;
          background: #ffffff;
          transform-origin: left top;
          transform: rotate(-90deg);
          margin-top: 138px;
          margin-right: -70px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-family: PingFang-SC-Semibold, PingFang-SC;
          font-weight: 600;
          color: #00c18b !important;
          background: #ffffff;
          border-radius: 4px;
          border: 2px solid #00c18b;

          span {
            word-spacing: 15px;
          }
        }

        .right {
          margin-left: 20px;

          // padding: 10px 0;
          .title {
            font-size: 36px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            // margin: 10px 0px;
            height: 30px;
            font-size: 30px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #00c18b;
          }

          p {
            font-size: 12px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #222222;
          }

          .Orderamount {
            margin-bottom: 18px;
          }
        }
      }

      span {
        // transform: rotate(180deg);
        // margin-left: 5px;
        word-spacing: 5px;
      }
    }

    // 优惠卷使用记录
    .couponRecord {
      margin-left: 10px;

      .search {
        /deep/.el-input__inner {
          width: 344px;
          height: 40px;
          border-radius: 4px;
          border-color: #c0c4cc;
        }

        /deep/.el-input__icon {
          color: #c0c4cc;
          font-weight: 1000;
        }

        /deep/ .el-select {
          .el-input__inner {
            width: 200px !important;
            height: 40px !important;
            background: #ffffff;
            border-radius: 4px;
            border-color: #c0c4cc;
          }
        }

        .input {
          display: inline-block;
          width: 390px;
          margin-left: 20px;
        }

        .el-button {
          border-radius: 4px;
          margin-left: 50px;
          transform: translate(-70px, 0);
        }
      }

      .table {
        margin-top: 20px;
      }

      .allpag {
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}
</style>
